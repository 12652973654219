import React, {Fragment} from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import LeftSideFunnels from './Funnel/LeftSideFunnels';
export default function LeftSide() {

    return (
        <Fragment>

            <LeftSideFunnels/>

        </Fragment>
    );
}

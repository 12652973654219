import React from 'react'
import { SummaryComponentTmp } from './SummaryComponentTmp'

export default function SummaryRoute() {
  return (
    <SummaryComponentTmp
    initConfigId='challenge-user-lesson-1'
    network={
        {
            url: '/app/nae-core/summary/statV2',
            type: 'post',
            data: {
                cacheToken: "eyJleHRyYURhdGEiOltdLCJmaWVsZHNUb1JldHVybiI6WyJzY29wZXMiLCJfdmlld1RpdGxlIiwiY2hhbGxlbmdlLnRpdGxlIiwiYXBwVXNlci5lbWFpbCIsImxlc3Nvbi50aXRsZSIsImxlc3Nvbi5sZXNzb25OdW1iZXIiLCJjb21wbGV0ZWRBdCJdLCJmaWx0ZXJzIjpbeyJvciI6W3siYW5kIjpbWyJpLmFwcFVzZXIuZW1haWwiLCJjb250YWlucyIsImNhdHJpbmZqb2huc3NvbkBnbWFpbC5jb20iLHRydWVdXX0seyJhbmQiOltbImkuYXBwVXNlci5lbWFpbCIsImNvbnRhaW5zIiwiS3Jpc3Rha25vb2tAZ21haWwuY29tIix0cnVlXV19LHsiYW5kIjpbWyJpLmFwcFVzZXIuZW1haWwiLCJjb250YWlucyIsIkFscGluZXRlcnJ5QG91dGxvb2suY29tIix0cnVlXV19XX0seyJvciI6W3siYW5kIjpbWyJpLmxlc3Nvbi5sZXNzb25OdW1iZXIiLCI9IiwiMSIsdHJ1ZV1dfSx7ImFuZCI6W1siaS5sZXNzb24ubGVzc29uTnVtYmVyIiwiPSIsIjIiLHRydWVdXX0seyJhbmQiOltbImkubGVzc29uLmxlc3Nvbk51bWJlciIsIj0iLCIzIix0cnVlXV19XX0seyJhbmQiOltbImkuaXNDb21wbGV0ZWQiLCI9Iix0cnVlLHRydWVdLFsiaS5hcHBVc2VyLmVtYWlsIiwibm90X2NvbnRhaW5zIiwibmV0emV0LmNvbSIsdHJ1ZV1dfV0sInBhZ2UiOjEsInBhZ2VTaXplIjo5OTk5OTksInNjaGVtYSI6ImNoYWxsZW5nZS11c2VyLWxlc3NvbiIsInNraXBQZXJtaXNzaW9uc0NoZWNrIjp0cnVlLCJzb3J0IjpbeyJrZXkiOiJpLmNoYWxsZW5nZS50aXRsZSIsInZhbHVlIjoiQVNDIn1dLCJ0b3RhbHMiOltdfQ=="
            }
        }
    }
    
    />
  )
}

import React, { Fragment, useEffect, useState } from 'react'
import { AdminConfig } from '../AdminConfig';
import LeftSideCreate from './LeftSideCreate';
import { MenuItem, MenuTitle } from '@newageerp/v3.bundles.menu-bundle'

export default function LeftSideFunnels() {
    // const router = useRouter();

    const [data, setData] = useState<any[]>([]);

    const getData = async () => {
        const res = await fetch(
            `${AdminConfig.url}/funnel/list`,
            {
                body: JSON.stringify({
                    project: AdminConfig.project,
                }),
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        );
        const resJson = await res.json();
        setData(resJson.data);
    }
    useEffect(() => {
        getData();
    }, []);

    const loadFunnel = (slug: string) => {
        // router.push(`/admin/funnel/item/${slug}`)
    }

    return (
        <Fragment>
            <LeftSideCreate reload={getData} />
            <MenuTitle>Funnels</MenuTitle>

            {data.map(f => {
                return (
                    <MenuItem key={`i-${f.slug}`} onClick={() => loadFunnel(f.slug)}>
                        {f.slug}
                    </MenuItem>
                )
            })}
        </Fragment>
    )
}

import FunnelRoute from "../Admin/Routes/FunnelRoute";
import { CustomEditComponentsMap } from "../_generated/_custom/edit/CustomEditComponentsMap";
import { CustomListComponentsMap } from "../_generated/_custom/tabs/CustomListComponentsMap";
import { CustomViewComponentsMap } from "../_generated/_custom/view/CustomViewComponentsMap";
import SummaryRoute from "./Tmp/SummaryRoute";
import UsersMenuItem from "./Users/UsersMenuItem";

export const PluginsMap: any = {
    ...CustomViewComponentsMap,
    ...CustomEditComponentsMap,
    ...CustomListComponentsMap,

    'custom.user.genToken': UsersMenuItem,
    
    'custom.routes': {
        '/tmp/summary': SummaryRoute,
    },

    'custom.noauthroutes': {
        '/admin/list': FunnelRoute,
    },
}
import { createSelector } from "redux-orm";
import orm from "./orm";

export const SelectorAchievementNae = createSelector(orm.AchievementModel);
export const SelectorAppUserNae = createSelector(orm.AppUserModel);
export const SelectorArticleNae = createSelector(orm.ArticleModel);
export const SelectorChallengeNae = createSelector(orm.ChallengeModel);
export const SelectorChallengeLessonNae = createSelector(orm.ChallengeLessonModel);
export const SelectorChallengeUserLessonNae = createSelector(orm.ChallengeUserLessonModel);
export const SelectorQueueNae = createSelector(orm.QueueModel);

export const selectorBySchemaClassName = (schema) => {
        if (schema === 'Achievement') {
        return SelectorAchievementNae;
    }
        if (schema === 'AppUser') {
        return SelectorAppUserNae;
    }
        if (schema === 'Article') {
        return SelectorArticleNae;
    }
        if (schema === 'Challenge') {
        return SelectorChallengeNae;
    }
        if (schema === 'ChallengeLesson') {
        return SelectorChallengeLessonNae;
    }
        if (schema === 'ChallengeUserLesson') {
        return SelectorChallengeUserLessonNae;
    }
        if (schema === 'Queue') {
        return SelectorQueueNae;
    }
    }

export const selectorBySchemaSlug = (schema) => {
        if (schema === 'achievement') {
        return SelectorAchievementNae;
    }
        if (schema === 'app-user') {
        return SelectorAppUserNae;
    }
        if (schema === 'article') {
        return SelectorArticleNae;
    }
        if (schema === 'challenge') {
        return SelectorChallengeNae;
    }
        if (schema === 'challenge-lesson') {
        return SelectorChallengeLessonNae;
    }
        if (schema === 'challenge-user-lesson') {
        return SelectorChallengeUserLessonNae;
    }
        if (schema === '') {
        return SelectorQueueNae;
    }
    }
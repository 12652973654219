
import React, { Fragment } from 'react'
import { PropsId, PropsLink } from './types';
import moment from "moment";
import { SelectorAchievementNae, SelectorAppUserNae, SelectorArticleNae, SelectorChallengeNae, SelectorChallengeLessonNae, SelectorChallengeUserLessonNae } from '../models/ormSelectors';
import { useAchievementHookNae } from '../hooks/useAchievementHookNae';
import { useAppUserHookNae } from '../hooks/useAppUserHookNae';
import { useArticleHookNae } from '../hooks/useArticleHookNae';
import { useChallengeHookNae } from '../hooks/useChallengeHookNae';
import { useChallengeLessonHookNae } from '../hooks/useChallengeLessonHookNae';
import { useChallengeUserLessonHookNae } from '../hooks/useChallengeUserLessonHookNae';


export const useEmptyHook = (id: number) => {
            return undefined;
        }

export const getHookForSchema = (schema: string) => {
  let selector : any = useEmptyHook;
  if (schema === 'achievement') {
    return useAchievementHookNae;
  }
  if (schema === 'app-user') {
    return useAppUserHookNae;
  }
  if (schema === 'article') {
    return useArticleHookNae;
  }
  if (schema === 'challenge') {
    return useChallengeHookNae;
  }
  if (schema === 'challenge-lesson') {
    return useChallengeLessonHookNae;
  }
  if (schema === 'challenge-user-lesson') {
    return useChallengeUserLessonHookNae;
  }
  return selector;
}

export function AchievementEmptyFieldNae(props: PropsLink) {
  const element = useAchievementHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function AppUserEmptyFieldNae(props: PropsLink) {
  const element = useAppUserHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function ArticleEmptyFieldNae(props: PropsLink) {
  const element = useArticleHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function ChallengeEmptyFieldNae(props: PropsLink) {
  const element = useChallengeHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function ChallengeLessonEmptyFieldNae(props: PropsLink) {
  const element = useChallengeLessonHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function ChallengeUserLessonEmptyFieldNae(props: PropsLink) {
  const element = useChallengeUserLessonHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}


import {MainPage} from '@newageerp/v3.bundles.layout-bundle';
import {RichEditorProvider} from '@newageerp/v3.form.rich-editor-draft';
import {TemplatesBuilder} from '@newageerp/v3.templates.templates-builder';
import classNames from 'classnames';
import React, {Fragment, useEffect, useState} from 'react';

import {AdminConfig} from "../AdminConfig";
import LeftSide from '../LeftSide';
import { Templates } from '../parser';

type Props = {
    slug: string;
};

export default function Item(props: Props) {
    const [isLoaded, setIsLoaded] = useState(false);

    let [data, setData] = useState<any[]>([]);
    let [components, setComponents] = useState<any[]>([]);

    const getComponents = async () => {
        const res = await fetch(`${AdminConfig.url}/component/list`, {
            body: JSON.stringify({
                project: AdminConfig.project,
            }),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const resJson = await res.json();
        setComponents(resJson.data);
    };

    const getItem = async (slug: string) => {
        const res = await fetch(`${AdminConfig.url}/funnel/get`, {
            body: JSON.stringify({
                slug,
                project: AdminConfig.project,
            }),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const resJson = await res.json();
        if (!!resJson.data && !!resJson.data.templates) {
            setData(resJson.data.templates);
        }

        setIsLoaded(true);
    };

    const updateItem = async (slug: string, templates: any) => {
        const res = await fetch(`${AdminConfig.url}/funnel/updateTemplates`, {
            body: JSON.stringify({
                slug,
                templates,
                project: AdminConfig.project,
            }),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const resJson = await res.json();
        if (!!resJson.data && !!resJson.data.templates) {
            setData(resJson.data.templates);
        }
    };

    useEffect(() => {
        getComponents();
    }, []);

    useEffect(() => {
        if (props.slug) {
            getItem(props.slug);
        }
        setIsLoaded(false);
    }, [props.slug]);

    if (!isLoaded) {
        return <Fragment/>;
    }

    let customTemplates: any = {};
    let _customComponents: any = [];
    if (components && components.length > 0) {
        components.forEach((_comp) => {
            const _compName = `custom_${_comp.slug}`;

            const _compExports: string[] = [];
            _comp.templates.forEach((el: any) => {
                if ('exports' in el) {
                    el.exports.forEach((ex: string) => {
                        _compExports.push(`${el.id}-${ex}`);
                    });
                }
            });

            customTemplates[_compName] = _comp.templates;

            _customComponents.push({
                id: _compName,
                placeholders: _compExports,
                schema: {
                    type: 'object',
                    // @ts-ignore
                    properties: {},
                },
                group: 'Custom',
                title: _comp.slug,
                comp: Fragment,
            });
        });
    }

    const _Templates = [...Templates, ..._customComponents];

    return (
        <MainPage sideBar={<LeftSide/>}>
            <div className={classNames('absolute left-[270px] bottom-0 right-0')}>
                <RichEditorProvider
                    toolbarOptions={{
                        options: [
                            'inline',
                            'blockType',
                            'list',
                            'textAlign',
                            'fontFamily',
                            'colorPicker',
                            'link',
                            'remove',
                            'history',
                        ],
                        colorPicker: {
                            colors: ['#00C2FF', '#F67F11', '#FFFFFF', '#000000', '#2748B3'],
                        },
                        fontFamily: {
                            options: ['Lexend'],
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                        },
                    }}
                >
                    <TemplatesBuilder
                        templates={_Templates}
                        data={data}
                        onSave={(data) => {
                            setData(data);
                            updateItem(props.slug, data);
                        }}
                        customTemplates={customTemplates}
                    />
                </RichEditorProvider>
            </div>
        </MainPage>
    );
}

import React, {Fragment, useState} from 'react';
import {MainButton} from '@newageerp/v3.bundles.buttons-bundle';
import {Popup} from '@newageerp/v3.bundles.popup-bundle';
import {Compact, CompactRow, CompactToolbar, FieldInput, FieldLabel} from '@newageerp/v3.bundles.form-bundle';
import { AdminConfig } from '../AdminConfig';

type LeftSideCreateProps = {
    reload: () => void;
};

export default function LeftSideCreate(props: LeftSideCreateProps) {
    const [isCreate, setIsCreate] = useState(false);
    const toggleCreate = () => setIsCreate(!isCreate);

    return (
        <Fragment>
            <div className="px-2">
                <MainButton iconName="plus" className="w-full" onClick={toggleCreate}>
                    Create
                </MainButton>
            </div>

            {isCreate && <CreateFunnelPopup isCreate={isCreate} toggleCreate={toggleCreate} afterSave={props.reload}/>}
        </Fragment>
    );
}

type CreateFunnelPopupProps = {
    isCreate: boolean;
    toggleCreate: () => void;
    afterSave: () => void;
};

const CreateFunnelPopup = (props: CreateFunnelPopupProps) => {
    const [slug, setSlug] = useState('');

    const onSave = async () => {
        await fetch(`${AdminConfig.url}/funnel/create`, {
            body: JSON.stringify({
                slug,
                project: AdminConfig.project,
            }),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        props.afterSave();
        props.toggleCreate();
    };

    return (
        <Popup isPopup={props.isCreate} onClick={props.toggleCreate} title="Funnel">
            <Compact>
                <CompactRow
                    label={<FieldLabel>Slug</FieldLabel>}
                    control={<FieldInput className="w-96" value={slug} onChange={(e) => setSlug(e.target.value)}/>}
                />
                <CompactToolbar onCancel={props.toggleCreate} onSave={onSave}/>
            </Compact>
        </Popup>
    );
};

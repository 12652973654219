import React, { ReactNode } from 'react'

import classNames from 'classnames'
import { DndContext, KeyboardSensor, PointerSensor, SortableContext, sortableKeyboardCoordinates, useSensor, useSensors, verticalListSortingStrategy, arrayMove } from '@newageerp/v3.gantt.gantt-chart';
import { closestCenter } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from "@dnd-kit/utilities";
import { useTheme } from '@newageerp/v3.layout.theme-provider';

type SummaryComponentSortProps = {
    data: {
        list: any[]
        set: (a: any) => void
        key: string
    },
    content?: {
        afterTitle?: (f: any) => ReactNode
    }
}

export default function SummaryComponentSort(props: SummaryComponentSortProps) {
    const { theme } = useTheme();

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragEndRows = (event: any) => {
        const activeId = event.active.id.replace('s-', '');
        const overId = event.over.id.replace('s-', '');

        const oldIndex = props.data.list.map(e => e[props.data.key]).indexOf(activeId);
        const newIndex = props.data.list.map(e => e[props.data.key]).indexOf(overId);
        const _items = arrayMove(props.data.list, oldIndex, newIndex);

        props.data.set(_items);
    }

    const toggleRowItemVal = (k: string) => {
        props.data.set(
            [...props.data.list.map(e => {
                if (e.key === k) {
                    return { ...e, val: !e.val }
                }
                return e;
            })]
        )
    }

    return (
        <DndContext
            sensors={sensors}
            onDragEnd={handleDragEndRows}
            collisionDetection={closestCenter}
        >

            <SortableContext
                items={props.data.list.map(r => `s-${r[props.data.key]}`)}
                strategy={verticalListSortingStrategy}

            >
                <div className='space-y-1'>
                    {props.data.list.map(r => {
                        return (
                            <SortableItem key={`r-s-${r.key}`} id={`s-${r.key}`} disable={false}>
                                {({ isDragging, listeners }) => {
                                    return (
                                        <div className={classNames(
                                            'flex gap-2 items-center',
                                            'text-sm',
                                            'px-2 py-1',
                                            'rounded',
                                            'border',
                                            theme.border.field
                                        )}>
                                            <div className={classNames(
                                                'flex gap-2 items-center',
                                                { 'opacity-80': isDragging }
                                            )}
                                                {...listeners}
                                            >
                                                <i className='fa-solid fa-up-down-left-right' />

                                                <p className='w-[160px] truncate'>{r.title}</p>
                                            </div>

                                            {!!props.content && !!props.content.afterTitle && props.content.afterTitle(r)}

                                            <button onClick={() => toggleRowItemVal(r.key)}>
                                                <i className={classNames({ 'text-teal-600 fa-solid': r.val }, { 'text-slate-400 fa-thin': !r.val }, ' fa-check')} />
                                            </button>
                                        </div>
                                    )
                                }}
                            </SortableItem>
                        )
                    })}
                </div>

            </SortableContext>
        </DndContext>
    )
}


export type SortableItemProps = {
    children: (props: { isSorting: boolean, isDragging: boolean, listeners?: any }) => ReactNode,
    disable: boolean,
    id: string,
}

const SortableItem = (props: SortableItemProps) => {
    const { attributes, listeners, setNodeRef, transform, transition, isSorting, isDragging } = useSortable({ id: props.id });

    const style: any = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    if (props.disable) {
        return <div>{props.children({ isSorting, isDragging })}</div>;
    }

    return <div
        ref={setNodeRef}
        style={style}

        {...attributes}
    >
        {props.children({ isSorting, isDragging, listeners })}
    </div>
}
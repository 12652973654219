import React, { Fragment, ReactNode, useEffect, useState } from 'react'
import { LogoLoader } from '@newageerp/v3.layout.logo-loader';
import { Table } from '@newageerp/v3.table.table';
import { Th } from '@newageerp/v3.table.th';
import { Td } from '@newageerp/v3.table.td';
import { NetworkProps, getNetworkRes } from '@newageerp/v4.network.network';
import classNames from 'classnames'
import { numberFormat } from '@newageerp/v3.bundles.utils-bundle';
import { Compact, CompactRow, FieldInputInt, FieldLabel, FieldSelect } from '@newageerp/v3.bundles.form-bundle';
import SummaryComponentSort from './SummaryComponentSort';
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';

export const valueOptions = [
    {
        value: 'count',
        label: "Count"
    },
    {
        value: 'sum',
        label: "Sum"
    },
    {
        value: 'max',
        label: "Max"
    },
    {
        value: 'min',
        label: "Min"
    },
    {
        value: 'avg',
        label: "Avg"
    },
    {
        value: 'count_percent',
        label: "Percent (Count)"
    },
    {
        value: 'sum_percent',
        label: "Percent (Sum)"
    },
]

export type SummaryComponentProps = {
    initConfigId: string,
    network: NetworkProps,
};

type RemoteConfigValue = {
    title: string,
    type: string,
    field: string,
    key: string,
    decimals?: number
}

type RemoteConfigCol = { title: string, key: string };

type RemoteConfig = {
    success: number,
    config: {
        title: string,
        cols: RemoteConfigCol[],
        values: RemoteConfigValue[],
        init: {
            rows: string[],
            columns: string[],
            values: string[],
        }
    },
    colValues: {
        [key: string]: string[],
    }
    data: any
    availableSelections: {
        id: string,
        title: string,
    }[]
}

type PivotItem = {
    key: string,
    val: boolean,
    title: string,
}

const borderLeftValue = 'shadow-[-4px_0px_0px_0px_rgb(96,165,250,1)]';

const classTitle = classNames('font-medium align-top max-w-[150px] truncate');
const classGrandCol = classNames('custom-bg font-bold !text-purple-600', 'shadow-[-2px_0px_0px_0px_rgb(192,132,252,1)]');
const classGrandRow = classNames('custom-bg text-purple-600', 'shadow-[0px_-2px_0px_0px_rgb(192,132,252,1)]');

const borderTop = 'shadow-[0px_-1px_0px_0px_rgb(96,165,250,1)]';


export function SummaryComponentTmp(props: SummaryComponentProps) {

    const [configId, setConfigId] = useState(props.initConfigId);

    const [rowItems, setRowItems] = useState<PivotItem[]>([]);
    const [columnItems, setColumnItems] = useState<PivotItem[]>([]);
    const [valueItems, setValueItems] = useState<PivotItem[]>([]);

    const [configValues, setConfigValues] = useState<RemoteConfigValue[]>([]);

    const rows = rowItems.filter(r => r.val).map(r => r.key);
    const columns = columnItems.filter(r => r.val).map(r => r.key);
    const values = valueItems.filter(r => r.val).map(r => r.key);

    const [remoteConfig, setRemoteConfig] = useState<RemoteConfig>()
    const [isLoading, setIsLoading] = useState(false);
    const loadData = async () => {
        setIsLoading(true);
        const res = await getNetworkRes({ ...props.network, data: { ...props.network.data, configId: configId } })

        if (res && res.data) {
            setRowItems(res.data.config.cols.map((c: RemoteConfigCol) => {
                return ({
                    key: c.key,
                    val: res.data.config.init.rows.indexOf(c.key) >= 0,
                    title: c.title
                })
            }))

            setColumnItems(res.data.config.cols.map((c: RemoteConfigCol) => {
                return ({
                    key: c.key,
                    val: res.data.config.init.columns.indexOf(c.key) >= 0,
                    title: c.title
                })
            }))

            setValueItems(res.data.config.values.map((c: RemoteConfigValue) => {
                return ({
                    key: c.field,
                    val: res.data.config.init.values.indexOf(c.field) >= 0,
                    title: c.title
                })
            }))

            setConfigValues(res.data.config.values);

            setRemoteConfig(res.data)
        }

        setIsLoading(false);
    }

    useEffect(() => {
        loadData();
    }, [configId, props.network]);

    const valByField = (k: string): RemoteConfigValue => {
        const r = remoteConfig ? configValues.find(e => e.field === k) : undefined;
        if (!r) {
            return {
                field: '',
                key: '',
                title: '',
                type: '',
            }
        }
        return r;
    }

    const colsToParse = !!remoteConfig && columns.length > 0 ? columns.map(nextCol => remoteConfig.colValues[nextCol].length).reduce((a, b) => a * b, 1) : 0;



    return (
        <div className='min-w-[600px] min-h-[400px] space-y-2'>
            {!!remoteConfig &&
                <WhiteCard>
                    <div className='flex gap-2 flex-wrap'>
                        <Compact>
                            <CompactRow
                                control={
                                    <SummaryComponentSort
                                        data={{
                                            key: 'key',
                                            list: rowItems,
                                            set: setRowItems
                                        }}
                                    />
                                }
                                label={<FieldLabel>Rows</FieldLabel>}
                            />
                        </Compact>

                        <Compact>
                            <CompactRow
                                control={<SummaryComponentSort
                                    data={{
                                        key: 'key',
                                        list: columnItems,
                                        set: setColumnItems
                                    }}
                                />}
                                label={<FieldLabel>Columns</FieldLabel>}
                            />
                        </Compact>

                        <Compact>
                            <CompactRow
                                control={<SummaryComponentSort
                                    data={{
                                        key: 'key',
                                        list: valueItems,
                                        set: setValueItems
                                    }}
                                    content={{
                                        afterTitle: (v) => {
                                            const _val = valByField(v.key);

                                            return (
                                                <Fragment>
                                                    <FieldSelect
                                                        className='w-[150px]'
                                                        options={valueOptions}
                                                        value={_val.type}
                                                        onChange={(t) => {
                                                            setConfigValues([...configValues.map(_v => {
                                                                if (_v.field === v.key) {
                                                                    return { ..._v, type: t }
                                                                }
                                                                return _v;
                                                            })])
                                                        }}
                                                    />
                                                    <FieldInputInt
                                                        title='Decimals'
                                                        placeholder='decimals'
                                                        className='w-[60px]'
                                                        value={_val.decimals ? _val.decimals : ""}
                                                        onChangeInt={(e) => {
                                                            setConfigValues([...configValues.map(_v => {
                                                                if (_v.field === v.key) {
                                                                    return { ..._v, decimals: e }
                                                                }
                                                                return _v;
                                                            })])
                                                        }}
                                                    />
                                                </Fragment>
                                            )
                                        }
                                    }}
                                />}
                                label={<FieldLabel>Values</FieldLabel>}
                            />
                        </Compact>

                    </div>
                </WhiteCard>
            }
            {isLoading && <div className='flex items-center h-[400px] justify-center'><LogoLoader /></div>}
            {!!remoteConfig && !isLoading &&
                <Table
                    className='stripped relative'
                    thead={
                        <thead className='sticky top-0 bg-white z-50'>
                            <tr>
                                {rows.map((row, rowIdx) => {
                                    const _col = remoteConfig.config.cols.find(el => el.key === row);
                                    return (<Th key={`thead-val-r-${rowIdx}`}>{_col?.title}</Th>)
                                })}
                                <Th></Th>
                                {values.map((val, valIdx) => {
                                    const _val = valByField(val);
                                    return <Fragment key={`val-${valIdx}`}>
                                        <Th colSpan={colsToParse} className={classNames(borderLeftValue)}>{_val.title}</Th>
                                        <Th className={classNames(classGrandCol)}>Grand total</Th>
                                    </Fragment>
                                })}
                            </tr>

                            {columns.map((col, colIdx) => {
                                const _col = remoteConfig.config.cols.find(el => el.key === col);
                                const _colValues = remoteConfig.colValues[col];

                                const nextColumns = columns.slice(colIdx + 1);
                                const nextColumnsLength = nextColumns.map(nextCol => remoteConfig.colValues[nextCol].length).reduce((a, b) => a * b, 1)

                                const prevColumns = columns.slice(0, colIdx);
                                const prevColumnsLength = prevColumns.map(nextCol => remoteConfig.colValues[nextCol].length).reduce((a, b) => a + b, 0)
                                const prevColumnsMap = Array(Math.max(prevColumnsLength, 1)).fill(0);

                                return (
                                    <tr key={`thead-${colIdx}`}>
                                        {rows.map((row, rowIdx) => <Th key={`thead-${colIdx}-r-${rowIdx}`}></Th>)}
                                        <Th className={classTitle}>{_col?.title}</Th>

                                        {values.map((val, valIdx) => {
                                            return (<Fragment key={`v-${valIdx}`}>

                                                {prevColumnsMap.map((x, xIdx) => <Fragment key={`v-${valIdx}-thead-${colIdx}-x-${xIdx}`}>
                                                    {_colValues.map((_colVal, _colValIdx) => <Th className={classNames(
                                                        { [borderLeftValue]: xIdx === 0 && _colValIdx === 0 }
                                                    )} colSpan={nextColumnsLength} key={`v-${valIdx}-thead-${colIdx}-x-${xIdx}-v-${_colValIdx}`}>{_colVal}</Th>)}
                                                </Fragment>)}

                                                <Th className={
                                                    classNames(
                                                        classGrandCol
                                                    )
                                                }></Th>
                                            </Fragment>)
                                        })}

                                    </tr>
                                )

                            })}

                            <tr>
                                {rows.map((row, rowIdx) => <Td className={classGrandRow} key={`grand-r-${rowIdx}`}>{rowIdx === 0 && "Grand total"}</Td>)}
                                <Td className={classGrandRow}></Td>

                                {values.map((val, valIdx) => {
                                    const value = configValues.find(e => e.field === val);
                                    if (!value) {
                                        return <Fragment key={`g-${valIdx}`} />
                                    }
                                    const grandTotalAll = !!remoteConfig ? calcColValue({ curData: remoteConfig.data, prevData: remoteConfig.data, value }) : 0;
                                    return (<Fragment key={`g-${valIdx}`}>

                                        <ParseColumn
                                            data={remoteConfig.data}
                                            value={value}
                                            colIdx={0}
                                            columns={columns}
                                            remoteConfig={remoteConfig}
                                            className={classGrandRow}
                                            disableDefBg={true}
                                            parentColVIdx={[]}
                                            classNameF={(parentColVIdx) => {
                                                const sumIdx = parentColVIdx.reduce((a, b) => a + b, 0);
                                                return sumIdx === 0 ? borderLeftValue : '';
                                            }}
                                        />
                                        <Td className={classNames(classGrandCol, classGrandRow, 'font-bold', 'text-right')}>
                                            {grandTotalAll}
                                        </Td>
                                    </Fragment>)
                                })}
                            </tr>

                        </thead>
                    }
                    tbody={
                        <tbody>



                            {rows.length > 0 &&
                                <ParseRow
                                    rows={rows}
                                    rowIdx={0}
                                    remoteConfig={remoteConfig}
                                    columns={columns}
                                    data={remoteConfig.data}
                                    parentRowVIdx={[]}
                                    values={values}
                                    configValues={configValues}

                                />
                            }


                        </tbody>
                    }
                />
            }
        </div>
    )
}


type ParseRowProps = {
    rows: string[]
    rowIdx: number
    columns: string[]
    remoteConfig: RemoteConfig
    data: any[]
    parentRowVIdx: number[]
    values: string[],
    configValues: RemoteConfigValue[]
}

const ParseRow = (props: ParseRowProps) => {

    const { rowIdx, rows, columns, remoteConfig, data, parentRowVIdx, values, configValues } = props;
    const nextRows = rows.slice(rowIdx + 1);

    const prevRows = rows.slice(0, rowIdx);

    const row = rows[rowIdx];
    const rowValues = remoteConfig.colValues[row];

    return (
        <Fragment>
            {rowValues.map((rowV, rowVidx) => {
                const rowVidxA = [...parentRowVIdx, rowVidx];

                const curData = data.filter((el: any) => el[row] === rowV);

                const tdClassName = classNames(
                    { [borderTop]: nextRows.length > 0 || rowVidx === 0 }
                );

                return <Fragment key={`row-${rowIdx}-v-${rowVidx}`}>
                    {nextRows.length === 0 &&
                        <tr className={''}>
                            {prevRows.map((pr, prIdx) => {
                                const sumIdx = rowVidxA.slice(prIdx + 1).reduce((a, b) => a + b, 0);

                                return (
                                    <Td
                                        key={`row-${rowIdx}-v-${rowVidx}-pr-${prIdx}`}
                                        className={
                                            classNames(
                                                classTitle,
                                                { [borderTop]: sumIdx === 0 }
                                            )
                                        }
                                        title={remoteConfig.colValues[pr][rowVidxA[prIdx]]}
                                    >
                                        {sumIdx === 0 && remoteConfig.colValues[pr][rowVidxA[prIdx]]}
                                    </Td>
                                )
                            })}
                            <Td className={classNames(tdClassName, classTitle)} title={rowV}>{rowV}</Td>

                            {nextRows.map((x, nextRowIdx) => (<Td key={`row-${rowIdx}-v-${rowVidx}-h-${nextRowIdx}`} className={tdClassName}></Td>))}

                            <Td className={classNames(tdClassName,)}></Td>

                            {values.map((val, valIdx) => {
                                const value = configValues.find(e => e.field === val);
                                if (!value) {
                                    return <Fragment key={`row-${rowIdx}-v-${rowVidx}-v-${valIdx}`}></Fragment>
                                }
                                const grandColValue = calcColValue({ curData, value, prevData: remoteConfig.data })

                                return (
                                    <Fragment key={`row-${rowIdx}-v-${rowVidx}-v-${valIdx}`}>
                                        <ParseColumn
                                            colIdx={0}
                                            columns={columns}
                                            remoteConfig={remoteConfig}
                                            value={value}
                                            data={curData}
                                            className={tdClassName}
                                            classNameF={(parentColVIdx) => {
                                                const sumIdx = parentColVIdx.reduce((a, b) => a + b, 0);
                                                return sumIdx === 0 ? borderLeftValue : '';
                                            }}
                                            parentColVIdx={[]}
                                        />

                                        <Td className={classNames(classGrandCol, tdClassName, 'text-right')}>
                                            {grandColValue}
                                        </Td>
                                    </Fragment>
                                )
                            })}

                        </tr>
                    }
                    {nextRows.length > 0 &&
                        <ParseRow
                            rows={rows}
                            rowIdx={rowIdx + 1}
                            remoteConfig={remoteConfig}
                            columns={columns}
                            data={curData}
                            parentRowVIdx={[...parentRowVIdx, rowVidx]}
                            values={values}
                            configValues={configValues}
                        />
                    }
                </Fragment>
            })}
        </Fragment>
    )
}

type ParseColumnProps = {
    data: any[],
    columns: string[]
    colIdx: number
    remoteConfig: RemoteConfig
    value: RemoteConfigValue
    className?: string,
    classNameF?: (parentColVIdx: number[]) => string,
    disableDefBg?: boolean
    parentColVIdx: number[]
}

const ParseColumn = (props: ParseColumnProps) => {
    const { columns, colIdx, data, remoteConfig, value, className, classNameF, disableDefBg, parentColVIdx } = props;

    if (columns.length === 0) {
        return <Fragment />
    }

    const nextColumns = columns.slice(colIdx + 1);

    const col = columns[colIdx];
    const colValues = remoteConfig.colValues[col];


    return (<Fragment>
        {colValues.map((colV, colVIdx) => {
            const curData = data.filter((el: any) => el[col] === colV);

            const colValue = calcColValue({ curData, value, prevData: remoteConfig.data });

            return <Fragment key={`col-${colIdx}-${colVIdx}`}>
                {nextColumns.length === 0 && <Td className={
                    classNames(
                        'custom-bg text-right',
                        // { 'bg-teal-50/50': curData.length > 0 && !disableDefBg },
                        // { 'bg-red-50/50': curData.length === 0 && !disableDefBg },
                        // {'bg-sky-50/20': colVIdx % 2 === 0},
                        // {'bg-sky-50': colVIdx % 2 !== 0},
                        className,
                        `${classNameF ? classNameF([...parentColVIdx, colVIdx]) : ''}`
                    )
                }>{colValue}</Td>}
                {nextColumns.length > 0 && <ParseColumn
                    data={curData}
                    value={value}
                    colIdx={colIdx + 1}
                    columns={columns}
                    remoteConfig={remoteConfig}
                    className={className}
                    classNameF={classNameF}
                    disableDefBg={disableDefBg}
                    parentColVIdx={[...parentColVIdx, colVIdx]}
                />}
            </Fragment>
        })}
    </Fragment>)

}

type ColValueProps = {
    value: RemoteConfigValue
    curData: any[],
    prevData: any[]
}

const calcColValue = (props: ColValueProps) => {
    const { value, curData, prevData } = props;

    let colValue = 0;
    let dec = 0;
    if (value.type === 'count') {
        colValue = curData.length;
    }
    if (value.type === 'count_percent') {
        dec = 2;
        colValue = curData.length / prevData.length * 100;
    }
    if (value.type === 'sum') {
        dec = 2;
        colValue = curData.map(el => el[value.key]).reduce((a, b) => a + b, 0);
    }
    if (value.type === 'sum_percent') {
        dec = 2;
        colValue = curData.map(el => el[value.key]).reduce((a, b) => a + b, 0) / prevData.map(el => el[value.key]).reduce((a, b) => a + b, 0) * 100;
    }
    if (value.type === 'avg') {
        dec = 2;
        colValue = curData.map(el => el[value.key]).reduce((a, b) => a + b, 0) / curData.length;
    }
    if (value.type === 'max') {
        const dat: number[] = curData.map(el => el[value.key])
        if (dat.length > 0) {
            colValue = Math.max(...dat);
        }
    }
    if (value.type === 'min') {
        const dat: number[] = curData.map(el => el[value.key])
        if (dat.length > 0) {
            colValue = Math.min(...dat);
        }
    }

    if ('decimals' in value && value.decimals !== undefined) {
        dec = value.decimals;
    }

    return numberFormat({
        userInput: colValue,
        decimals: dec,
        decPoint: '.',
        thousandsSep: ' '
    });
}

import { IAchievementFieldsNae } from './useAchievementHookNae';
import { IAppUserFieldsNae } from './useAppUserHookNae';
import { IArticleFieldsNae } from './useArticleHookNae';
import { IChallengeFieldsNae } from './useChallengeHookNae';
import { IChallengeLessonFieldsNae } from './useChallengeLessonHookNae';
import { IChallengeUserLessonFieldsNae } from './useChallengeUserLessonHookNae';
import { SelectorAchievementNae,SelectorAppUserNae,SelectorArticleNae,SelectorChallengeNae,SelectorChallengeLessonNae,SelectorChallengeUserLessonNae, } from "../models/ormSelectors"
import { NaeSSchemaMap } from '../config/NaeSSchema';
import { store } from '../models/ormstore';

export const getDataCacheForSchema = (className: string) => {
    if (className === NaeSSchemaMap.Achievement.className) {
            return {
                dataToCheck: SelectorAchievementNae(store.getState()),
                fields: IAchievementFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.AppUser.className) {
            return {
                dataToCheck: SelectorAppUserNae(store.getState()),
                fields: IAppUserFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.Article.className) {
            return {
                dataToCheck: SelectorArticleNae(store.getState()),
                fields: IArticleFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.Challenge.className) {
            return {
                dataToCheck: SelectorChallengeNae(store.getState()),
                fields: IChallengeFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.ChallengeLesson.className) {
            return {
                dataToCheck: SelectorChallengeLessonNae(store.getState()),
                fields: IChallengeLessonFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.ChallengeUserLesson.className) {
            return {
                dataToCheck: SelectorChallengeUserLessonNae(store.getState()),
                fields: IChallengeUserLessonFieldsNae,    
            }
        }
        return undefined;
}
import { ORM } from "redux-orm";
import AchievementModel from "./AchievementModel"
import AppUserModel from "./AppUserModel"
import ArticleModel from "./ArticleModel"
import ChallengeModel from "./ChallengeModel"
import ChallengeLessonModel from "./ChallengeLessonModel"
import ChallengeUserLessonModel from "./ChallengeUserLessonModel"
import QueueModel from "./QueueModel"

export const orm = new ORM({
    stateSelector: (state) => {
        return state.orm; // wherever the reducer is put during createStore
    },
});
orm.register(
    AchievementModel,
    AppUserModel,
    ArticleModel,
    ChallengeModel,
    ChallengeLessonModel,
    ChallengeUserLessonModel,
    QueueModel,
);

export default orm;